import React, { forwardRef } from "react";
import clsx from "clsx";
import type { FieldError } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

interface InputProps {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;

  id: string;
  label?: string;
  type?: string;
  name?: string;
  value?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  hiddenLabel?: boolean;
  //   fullWidth?: boolean
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError;

  required?: boolean;
  description?: string;
  defaultValue?: string;
}

export const Input = forwardRef(
  (
    {
      id,
      name,
      type,
      required = false,
      defaultValue,
      placeholder,
      label,
      error,
      className,
      style,
      inputClassName,
      labelClassName,
      description,
      disabled,
      ...rest
    }: InputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={className} style={style}>
        {label ? (
          <label
            className={clsx(
              "block text-lg font-semibold tracking-wide text-[#141414]",
              labelClassName,
            )}
            htmlFor={id || name}
          >
            {label}
            {required ? <span className="text-red-500"> *</span> : null}
          </label>
        ) : null}

        <div className="relative mt-1">
          {id ? (
            <input
              aria-describedby={
                error ? `${id}-error` : description && `${id}-description`
              }
              className={clsx(
                "block w-full appearance-none py-2 text-black",
                "border-b placeholder-gray-400 focus:outline-none",
                "focus:ring-light focus:border-light focus:border-black sm:text-sm",
                disabled && "opacity-50",
                error &&
                  "block w-full border-b-2 border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm",
                inputClassName,
              )}
              defaultValue={defaultValue}
              disabled={disabled}
              id={id}
              name={name}
              placeholder={placeholder}
              ref={ref}
              required={required}
              type={type}
              {...rest}
            />
          ) : null}

          {error && id ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          ) : null}
        </div>

        {error?.message ? (
          <div>
            <span className="mt-2 text-sm text-red-600" id={`${id}-error`}>
              {error.message}
            </span>
          </div>
        ) : null}

        {description ? (
          <div>
            <span
              className="mt-2 text-sm text-gray-500"
              id={`${id}-description`}
            >
              {description}
            </span>
          </div>
        ) : null}
      </div>
    );
  },
);

Input.displayName = "Input";
