import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "transition duration-300 inline-flex text-center items-center leading-none justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 font-normal font-semibold text-base",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 font-semibold text-base",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground font-semibold text-base",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 font-semibold text-base",
        ghost:
          "text-primary hover:bg-accent hover:text-accent-foreground font-semibold text-base",
        link: "group text-[hsl(0, 0%, 8%)] underline-offset-4 font-normal inline-block text-lg",
      },
      size: {
        default: "px-6 py-2.5",
        sm: "px-3 py-1.5",
        lg: "px-8 py-3",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  /**
   *  Render the button as the child component, passing all props to the child
   */
  asChild?: boolean;

  /**
   * disable and show a loading spinner
   */
  isLoading?: boolean;

  /**
   * invert the underline color on the link variant
   */
  invertUnderline?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant,
      size,
      asChild = false,
      isLoading = false,
      invertUnderline = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    const childEl = asChild ? (
      React.cloneElement(children as React.ReactElement, {
        children: children?.props?.children ? (
          <>
            {children.props?.children}
            {variant === "link" ? (
              <ButtonLinkUnderline invert={invertUnderline} />
            ) : null}
          </>
        ) : children?.props?.dangerouslySetInnerHTML ? (
          <>
            <span
              dangerouslySetInnerHTML={children.props?.dangerouslySetInnerHTML}
            />
            {variant === "link" ? (
              <ButtonLinkUnderline invert={invertUnderline} />
            ) : null}
          </>
        ) : null,
        dangerouslySetInnerHTML: null,
      })
    ) : (
      <>
        {children}
        {variant === "link" ? (
          <ButtonLinkUnderline invert={invertUnderline} />
        ) : null}
        {isLoading ? (
          <span
            className={cn(
              buttonVariants({ variant, size }),
              `absolute inset-0 flex items-center justify-center text-center opacity-0`,
              isLoading && `opacity-100`,
            )}
          >
            <svg
              className="h-5 w-5 animate-spin text-inherit"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"
              />
            </svg>
          </span>
        ) : null}
      </>
    );

    return (
      <Comp
        className={cn("relative", buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      >
        {childEl}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
export default Button;

function ButtonLinkUnderline({
  className,
  invert,
}: {
  className?: string;
  invert?: boolean;
}) {
  return (
    <span
      className={cn(
        "block h-0.5 max-w-0 bg-primary transition-all duration-500 group-hover:max-w-full group-focus:max-w-full",
        invert && "bg-white",
        className,
      )}
    />
  );
}
