"use client";
import { useRef } from "react";
import { cn } from "@/lib/utils";
import Button from "@/components/ui/button";

export function Slider({
  variant = "default",
  className,
  children,
}: {
  variant?: "default" | "dark";
  className?: string;
  children?: React.ReactNode;
}) {
  const slider = useRef<HTMLDivElement>(null);

  const goNext = () => {
    const sliderItems = slider.current?.childNodes as
      | NodeListOf<HTMLDivElement>
      | undefined;
    const itemWidth = sliderItems?.[0]?.offsetWidth;

    if (itemWidth) {
      slider.current?.scrollTo({
        left: slider.current.scrollLeft + itemWidth,
        behavior: "smooth",
      });
    }
  };

  const goPrev = () => {
    const sliderItems = slider.current?.childNodes as
      | NodeListOf<HTMLDivElement>
      | undefined;
    const itemWidth = sliderItems?.[0]?.offsetWidth;

    if (itemWidth) {
      slider.current?.scrollTo({
        left: slider.current.scrollLeft - itemWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={cn("relative mb-20 w-full", className)}>
      <div className="edges relative z-10 flex justify-end gap-x-5 py-10">
        <PrevArrow onClick={goPrev} variant={variant} />
        <NextArrow onClick={goNext} variant={variant} />
      </div>

      <div
        className="no-scrollbar edges flex items-stretch overflow-x-scroll"
        ref={slider}
      >
        {children}
      </div>
    </div>
  );
}

function NextArrow({
  onClick,
  variant,
}: {
  onClick: () => void;

  variant?: string;
}) {
  return (
    <Button aria-label="Next" onClick={onClick} size="icon" variant={null}>
      <svg
        className={cn(variant === "dark" ? "text-primary" : "text-white")}
        fill="none"
        height="46"
        viewBox="0 0 46 46"
        width="46"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 0.25C18.5005 0.25 14.102 1.58426 10.3608 4.08407C6.61957 6.58387 3.70364 10.1369 1.98175 14.294C0.259857 18.451 -0.190669 23.0252 0.687145 27.4383C1.56496 31.8514 3.73169 35.905 6.91333 39.0867C10.095 42.2683 14.1486 44.435 18.5617 45.3129C22.9748 46.1907 27.549 45.7402 31.7061 44.0183C35.8631 42.2964 39.4161 39.3804 41.9159 35.6392C44.4157 31.898 45.75 27.4995 45.75 23C45.7436 16.9683 43.3447 11.1854 39.0797 6.92035C34.8146 2.65528 29.0317 0.25637 23 0.25ZM29.4881 24.2381L20.7381 32.9881C20.5755 33.1507 20.3825 33.2797 20.1701 33.3677C19.9576 33.4557 19.7299 33.501 19.5 33.501C19.2701 33.501 19.0424 33.4557 18.8299 33.3677C18.6175 33.2797 18.4245 33.1507 18.2619 32.9881C18.0993 32.8255 17.9703 32.6325 17.8823 32.4201C17.7943 32.2076 17.749 31.9799 17.749 31.75C17.749 31.5201 17.7943 31.2924 17.8823 31.0799C17.9703 30.8675 18.0993 30.6745 18.2619 30.5119L25.7759 23L18.2619 15.4881C17.9335 15.1598 17.749 14.7144 17.749 14.25C17.749 13.7856 17.9335 13.3402 18.2619 13.0119C18.5903 12.6835 19.0356 12.499 19.5 12.499C19.9644 12.499 20.4098 12.6835 20.7381 13.0119L29.4881 21.7619C29.6508 21.9244 29.7799 22.1174 29.868 22.3299C29.9561 22.5423 30.0014 22.77 30.0014 23C30.0014 23.23 29.9561 23.4577 29.868 23.6701C29.7799 23.8826 29.6508 24.0756 29.4881 24.2381Z"
          fill="currentColor"
        />
      </svg>
    </Button>
  );
}

function PrevArrow({
  onClick,
  variant,
}: {
  onClick: () => void;
  variant?: string;
}) {
  return (
    <Button aria-label="Previous" onClick={onClick} size="icon" variant={null}>
      <svg
        className={cn(variant === "dark" ? "text-primary" : "text-white")}
        fill="none"
        height="46"
        viewBox="0 0 46 46"
        width="46"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.0005 45.7504C27.5 45.7504 31.8985 44.4161 35.6397 41.9163C39.3809 39.4165 42.2968 35.8634 44.0187 31.7064C45.7406 27.5494 46.1912 22.9751 45.3133 18.5621C44.4355 14.149 42.2688 10.0953 39.0872 6.91368C35.9055 3.73204 31.8519 1.56531 27.4388 0.687498C23.0257 -0.190317 18.4515 0.26021 14.2944 1.98211C10.1374 3.704 6.58435 6.61992 4.08454 10.3611C1.58474 14.1024 0.250479 18.5008 0.250479 23.0004C0.256849 29.0321 2.65576 34.8149 6.92083 39.08C11.1859 43.3451 16.9688 45.744 23.0005 45.7504ZM16.5124 21.7622L25.2624 13.0122C25.4249 12.8496 25.618 12.7207 25.8304 12.6327C26.0428 12.5447 26.2705 12.4994 26.5005 12.4994C26.7304 12.4994 26.9581 12.5447 27.1705 12.6327C27.383 12.7207 27.576 12.8496 27.7386 13.0122C27.9012 13.1748 28.0302 13.3679 28.1182 13.5803C28.2062 13.7927 28.2515 14.0204 28.2515 14.2504C28.2515 14.4803 28.2062 14.708 28.1182 14.9204C28.0302 15.1329 27.9012 15.3259 27.7386 15.4885L20.2245 23.0004L27.7386 30.5122C28.067 30.8406 28.2515 31.286 28.2515 31.7504C28.2515 32.2148 28.067 32.6601 27.7386 32.9885C27.4102 33.3169 26.9649 33.5013 26.5005 33.5013C26.0361 33.5013 25.5907 33.3169 25.2624 32.9885L16.5124 24.2385C16.3496 24.076 16.2206 23.883 16.1325 23.6705C16.0444 23.4581 15.9991 23.2303 15.9991 23.0004C15.9991 22.7704 16.0444 22.5427 16.1325 22.3302C16.2206 22.1178 16.3496 21.9248 16.5124 21.7622Z"
          fill="currentColor"
        />
      </svg>
    </Button>
  );
}
