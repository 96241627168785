import React from "react";

// import { InputProps } from "./InputTypes";
interface InputProps {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  id: string;
  content?: string;
  type?: string;
  name?: string;
  value?: string;
  description?: string;
  // placeholder?: string;
  // hiddenLabel?: boolean;
  //   fullWidth?: boolean
  // disabled?: boolean;
  // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // error?: any;

  required?: false;
}

export function Description({
  id,
  name,
  type,
  className,
  inputClassName,
  labelClassName,
  description,
  content,
  ...rest
}: InputProps) {
  return (
    <div className={className}>
      <div>
        {description ? (
          <span className="mt-2 text-sm text-gray-500" id={`${id}-description`}>
            {description}
          </span>
        ) : null}
        {content ? (
          <div
            className="mt-2 text-sm text-gray-500"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            id={id}
          />
        ) : null}
      </div>
    </div>
  );
}
