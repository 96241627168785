"use client";
import { Fragment, forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { cn } from "@/lib/utils";
import Button from "../ui/button";
import { Input } from "./fields/input";
import { Description } from "./fields/description";
import Textarea from "./fields/textarea/Textarea";
import Error from "./alert/error";
import Success from "./alert/success";
import { Select } from "./fields/select";

export default function Form({
  form,
}: {
  form: {
    id: number;
    fields: {
      id: number;
      type: string;
      label: string;
      placeholder: string;
      isRequired: boolean;
      inputs: {
        id: string;
        label: string;
        isHidden: boolean;
        autocompleteAttribute: string;
      }[];
      displayOnly: boolean;
      content: string;
    }[];
  };
}) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaError, setRecaptchaError] = useState("");

  const {
    mutate: submitForm,
    data,
    isLoading,
    isError: isMutationError,
  } = useMutation(async (formValues: { id: number; value: string }[]) => {
    const formData = new FormData();
    for (const item of formValues) {
      formData.append(`input_${item.id}`, item.value);
    }

    if (!executeRecaptcha) {
      console.error("Recaptcha not loaded");
      setRecaptchaError("Recaptcha validation failed");
      return;
    }

    try {
      const gReCaptchaToken = await executeRecaptcha("formSubmit");
      const { valid } = await validateRecaptcha(gReCaptchaToken);

      if (!valid) {
        throw new window.Error("Recaptcha validation failed");
      }
    } catch (error) {
      console.error(error);
      setRecaptchaError("Recaptcha validation failed");
      return;
    }

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_WP_URL}/wp-json/gf/v2/forms/${form.id}/submissions`,
      {
        method: "POST",
        body: formData,
      },
    );

    const data = await res.json();

    if (!data.is_valid) {
      for (const key in data?.validation_messages) {
        setError(key, {
          type: "validation",
          message: data?.validation_messages[key],
        });
      }
    }
    return data;
  });

  const onSubmit = (formValues: any) => {
    const formattedData = formatData(formValues);
    submitForm(formattedData);
  };

  const isError =
    Boolean(data?.validation_messages?.length) ||
    isMutationError ||
    recaptchaError;
  const isSuccess = Boolean(data?.is_valid);
  const successMessage = data?.confirmation_message;

  return (
    <>
      {isError ? <Error errors={errors} /> : null}
      {isSuccess ? <Success>{successMessage}</Success> : null}

      {!isSuccess && (
        <form
          className="w-full"
          onSubmit={(e) => {
            clearErrors(); // clear errors set by the server to let us attempt submit again
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="flex grid-cols-12 flex-col gap-x-10 gap-y-5 md:grid">
            {form.fields.map((field, index) => {
              const inputId = `${field.type}_${field.id}`;
              const error = errors[field.id];
              const displayOnly = field.displayOnly;

              return (
                <Fragment key={index}>
                  {displayOnly ? (
                    <FormField field={field} />
                  ) : (
                    <FormField
                      error={error}
                      field={field}
                      inputId={inputId}
                      register={register}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>

          <Button className="mt-[30px]" disabled={isLoading} type="submit">
            {isLoading ? (
              <span className="flex items-center">
                <svg
                  className="-ml-1 mr-3 h-5 w-5 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  />
                </svg>
                {form.button.text}
              </span>
            ) : (
              form.button.text
            )}
          </Button>
        </form>
      )}
    </>
  );
}

const FormField = forwardRef(({ inputId, field, error, register }, ref) => {
  const getColsClassName = (cols) => {
    if (cols === 6) {
      return "col-span-12 md:col-span-6";
    }
    if (cols === 5) {
      return "col-span-12 md:col-span-5";
    }
    if (cols === 4) {
      return "col-span-12 md:col-span-4";
    }
    if (cols === 3) {
      return "col-span-12 md:col-span-3";
    }
    if (cols === 2) {
      return "col-span-12 md:col-span-2";
    }
    if (cols === 1) {
      return "col-span-12 md:col-span-1";
    }
  };
  const inputProps = {
    className: getColsClassName(field?.layoutGridColumnSpan) || "col-span-12",
    id: field.id,
    label: field.label,
    type: field.type,
    placeholder: field.placeholder,
    error,
    ref,
  };

  if (field.isRequired) {
    inputProps.required = true;
  }

  switch (field.type) {
    case "select":
      return (
        <Select
          inputId={inputId}
          options={field.choices.map((choice) => choice.value)}
          {...inputProps}
          register={register}
        />
      );
    case "email":
      return (
        <Input
          {...inputProps}
          {...register(inputId, { required: field.isRequired })}
        />
      );
    case "textarea":
      return (
        <Textarea
          {...inputProps}
          rows="4"
          {...register(inputId, { required: field.isRequired })}
        />
      );
    case "text":
      return (
        <Input
          {...inputProps}
          {...register(inputId, { required: field.isRequired })}
        />
      );
    case "name":
      return (
        <>
          {field.inputs
            ?.filter((item) => !item.isHidden)
            .map((item, index) => {
              const subInputProps = {
                required: Boolean(field.isRequired),
                // ...item,
                id: item.id,
                label: item.label,
                autoComplete: item.autocompleteAttribute,
                error: Boolean(error),
                ref,
              };

              const subItemInputId = `name_${item.id?.replace(".", "_")}`;

              return (
                <Input
                  className={cn("col-span-12 md:col-span-6")}
                  key={index}
                  {...subInputProps}
                  {...register(subItemInputId, {
                    required: field.isRequired,
                  })}
                />
              );
            })}
        </>
      );

    // case 'HTML':
    // 	return (
    // 		<div
    // 			dangerouslySetInnerHTML={{
    // 				__html: field?.content,
    // 			}}
    // 		/>
    // 	);
    case "captcha":
      return (
        <Input
          {...inputProps}
          {...register(inputId, { required: field.isRequired })}
        />
      );
    case "html":
      return (
        <Description
          {...inputProps}
          {...register(inputId, { required: field.isRequired })}
        />
      );
    default:
      return (
        <Input
          {...inputProps}
          {...register(inputId, { required: field.isRequired })}
        />
      );
  }
});

FormField.displayName = "FormField";

const formatData = (data) => {
  const formattedData = Object.entries(data).map(([key, value]) => {
    // const fieldType = key.split("_")[0];
    // const fieldId = key.split("_")[1];
    // const subFieldId = key.split("_")[2];

    const [fieldType, fieldId, subFieldId] = key.split("_");

    const fieldData = {
      id: subFieldId ? `${fieldId}_${subFieldId}` : fieldId,
    };

    switch (fieldType) {
      // case "email":
      //   fieldData.emailValues = { value };
      //   break;
      case "html":
        {
          console.log(fieldData);
        }
        break;

      default:
        fieldData.value = value;
        break;
    }

    return fieldData;
  });

  return formattedData;
};

async function validateRecaptcha(gRecaptchaToken: string): Promise<{
  valid: boolean;
  score: number;
}> {
  const response = await fetch("/api/recaptcha", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ gRecaptchaToken }),
  });

  const data = await response.json();

  return {
    valid: data?.success || false,
    score: data?.score || 0,
  };
}
